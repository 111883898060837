import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import RouterDevtools from "@/features/layout/routerDevTools.tsx";
import { AuthContext } from "@/features/layout/auth/auth.context.ts";
import { QueryClient } from "@tanstack/react-query";
import NotFound from "@/features/layout/notFound.tsx";
import ErrorComponent from "@/features/layout/errorBoundary.tsx";
import Pageview from "@/features/layout/analytics/pageview.ts";

type RouterContext = {
  auth: AuthContext;
  queryClient: QueryClient;
};

export const Route = createRootRouteWithContext<RouterContext>()({
  component: () => (
    <main>
      <Outlet />
      <Pageview />
      <RouterDevtools />
    </main>
  ),
  notFoundComponent: NotFound,
  errorComponent: ErrorComponent,
});
