import React from "react";
import { z } from "zod";
import { assistantSchema } from "@/schemas/assistant.ts";
import useMediaQuery from "@/hooks/useMediaQuery.ts";

const dashboardStoreSchema = z.object({
  assistants: z.array(assistantSchema),
  isMobile: z.boolean().optional(),
  sidebarVisible: z.boolean().optional(),
  setSidebarVisible: z.function().args(z.boolean()).returns(z.void()),
});

type DashboardStore = z.infer<typeof dashboardStoreSchema>;

const DashboardContext = React.createContext<DashboardStore | undefined>(
  undefined,
);

type DashboardProviderProps = React.PropsWithChildren & {
  assistants: DashboardStore["assistants"];
};

export const DashboardProvider: React.FC<DashboardProviderProps> = ({
  children,
  assistants,
}) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [sidebarVisible, setSidebarVisible] = React.useState(true);
  const values = React.useMemo(
    () => ({
      assistants,
      isMobile,
      sidebarVisible,
      setSidebarVisible,
    }),
    [assistants, isMobile, sidebarVisible, setSidebarVisible],
  );
  return (
    <DashboardContext.Provider value={values}>
      {children}
    </DashboardContext.Provider>
  );
};

export function useDashboardContext() {
  const context = React.useContext(DashboardContext);
  if (!context) {
    throw new Error(
      "useDashboardContext must be used within a DashboardProvider",
    );
  }
  return context;
}
