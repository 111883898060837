import { z } from "zod";
import { gptModelSchema } from "@/schemas/system.ts";

export const assistantSchema = z.object({
  id: z.number().int().readonly(),
  name: z
    .string()
    .min(1, { message: "Введите название ассистента" })
    .max(255, { message: "Название должно быть не более 255 символов" }),
  openai_id: z.string().readonly(),
  message_collection_time: z.union([
    z.literal(1),
    z.literal(2),
    z.literal(5),
    z.literal(10),
    z.literal(15),
    z.literal(20),
    z.literal(25),
    z.literal(30),
  ]),
  gpt_model: gptModelSchema.shape.id,
  temperature: z.number(),
  top_p: z.number(),
  instruction: z.string(),
  // services: z.array(z.number().int()).nullable(),
  // tools: z.string().readonly(),
});

export const createAssistantSchema = z.object({
  name: assistantSchema.shape.name,
  company: z
    .string()
    .min(1, { message: "Введите название компании" })
    .max(255, { message: "Название должно быть не более 255 символов" }),
  role: z.string().min(1, { message: "Введите роль ассистента" }),
  communication_style: z.string().min(1, { message: "Введите стиль общения" }),
  interruption_reason: z.string().min(1, { message: "Введите тему прерыва" }),
  default_response: z
    .string()
    .min(1, { message: "Введите ответ по умолчанию" }),
  additional_info: z
    .string()
    .min(1, { message: "Введите дополнительную информацию" }),
});

export const createAssistantRespSchema = z.object({
  id: z.number().readonly(),
  ...createAssistantSchema.shape,
});

export const updateAssistantSchema = z
  .object({
    name: assistantSchema.shape.name,
    message_collection_time: z.string(),
    temperature: assistantSchema.shape.temperature,
    top_p: assistantSchema.shape.top_p,
    gpt_model: z.string(),
    instruction: assistantSchema.shape.instruction,
  })
  .partial();

export type Assistant = z.infer<typeof assistantSchema>;
