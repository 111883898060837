import { queryOptions } from "@tanstack/react-query";
import { assistantAPI } from "@/services/api/assistant.ts";

class AssistantQueries {
  getAssistants = () => {
    return queryOptions({
      queryKey: ["assistants"],
      queryFn: assistantAPI.getAssistants,
      staleTime: Infinity,
    });
  };

  getAssistantById = (id: number) => {
    return queryOptions({
      queryKey: ["assistants", id],
      queryFn: () => assistantAPI.getAssistantById(id),
      staleTime: Infinity,
    });
  };

  // getAssistantFile = (assistant_pk: string) => {
  //   return queryOptions({
  //     queryKey: ["assistants", "files", assistant_pk],
  //     queryFn: () => assistantAPI.getAssistantFile(assistant_pk),
  //   });
  // };
  //
  // getAssistantFileById = (id: number, assistant_pk: string) => {
  //   return queryOptions({
  //     queryKey: ["assistants", "files", id, assistant_pk],
  //     queryFn: () => assistantAPI.getAssistantFileById(id, assistant_pk),
  //   });
  // };
}

export const assistantQueries = new AssistantQueries();
