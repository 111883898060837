import { z } from "zod";
import {
  passwordFormSchema,
  phoneNumberFormSchema,
  phoneNumberSchema,
  smsCodeRespSchema,
  tokensRespSchema,
} from "@/schemas/shared.ts";
import { userSchema } from "@/schemas/user.ts";

// login

export const loginPropsSchema = z.object({
  phone_number: phoneNumberSchema,
  password: z.string({ message: "Введите пароль" }),
});

export const loginRespSchema = z.object({
  id: userSchema.shape.id,
  phone_number: userSchema.shape.phone_number,
  tokens: tokensRespSchema,
});

export const loginFormSchema = z.object({
  phone_number: phoneNumberFormSchema,
  password: z.string().optional(),
});

// send sms

export const sendSmsPropsSchema = z.object({
  phone_number: phoneNumberSchema,
});

export const sendSmsRespSchema = smsCodeRespSchema;

// verify sms

export const verifySmsPropsSchema = z.object({
  code: z.string().regex(/\d{4}/, { message: "Введите код" }),
  uid: smsCodeRespSchema.shape.uid,
  is_password_reset: z.boolean().optional(),
  registration_id: z.string().optional(),
});

export const verifySmsFormSchema = verifySmsPropsSchema.pick({
  code: true,
});

export const verifySmsRespSchema = loginRespSchema;

// register

export const registerPropsSchema = z.object({
  phone_number: phoneNumberSchema,
  password: z.string({ message: "Введите пароль" }),
});

export const registerRespSchema = smsCodeRespSchema.extend({
  registration_id: z.string(),
});

export const registerFormSchema = z.object({
  phone_number: phoneNumberFormSchema,
  password: passwordFormSchema,
});

// forgot password

export const forgotPasswordPropsSchema = z.object({
  phone_number: phoneNumberSchema,
});

export const forgotPasswordRespSchema = smsCodeRespSchema;

export const forgotPasswordFormSchema = z.object({
  phone_number: phoneNumberFormSchema,
});

// reset password

export const resetPasswordPropsSchema = z.object({
  phone_number: phoneNumberSchema,
  new_password: z.string({ message: "Введите пароль" }),
  confirm_password: z.string({ message: "Повторите пароль" }),
});

export const resetPasswordRespSchema = z.object({});

export const resetPasswordFormSchema = z.object({
  password: passwordFormSchema,
  phoneNumberSchema: phoneNumberFormSchema,
});

// refresh token

export const refreshTokenPropsSchema = z.object({
  refresh: z.string(),
});

export const refreshTokenRespSchema = tokensRespSchema;
