import { z } from "zod";

// shared

export const listSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string(),
  }),
);

// gpt-models

export const gptModelSchema = z.object({
  id: z.number().readonly(),
  description: z.string(),
  input_cost: z.number(),
  name: z.string(),
  output_cost: z.number(),
});

export type GPTModel = z.infer<typeof gptModelSchema>;

// messanger-types

export const messangerTypes = z.union([
  z.literal("telegram"),
  z.literal("whatsapp"),
  z.literal("playground"),
]);

// features

const availableFeaturesSchema = listSchema;

export type AvailableFeatures = z.infer<typeof availableFeaturesSchema>;

// services

const availableServicesSchema = listSchema;

export type AvailableServices = z.infer<typeof availableServicesSchema>;
