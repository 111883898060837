import React, { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { routeTree } from "./routeTree.gen";
import { useAuth } from "@/features/layout/auth/auth.context.ts";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { TooltipProvider } from "@/features/ui/tooltip.tsx";
import PosthogProvider from "@/features/layout/analytics/posthog.provider.tsx";
import { initSentry } from "@/lib/sentry.ts";

import "@/styles/index.css";

const AuthProvider = React.lazy(
  () => import("@/features/layout/auth/auth.provider.tsx"),
);

const ThemeProvider = React.lazy(
  () => import("@/features/layout/theme/theme.tsx"),
);

const Toaster = React.lazy(() => import("@/features/ui/sonner.tsx"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  context: {
    auth: undefined!,
    queryClient,
  },
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

initSentry();

const InnerApp = () => {
  const auth = useAuth();
  return <RouterProvider router={router} context={{ auth }} />;
};

const rootElement = document.getElementById("app")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <PosthogProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <TooltipProvider>
              <AuthProvider>
                <InnerApp />
                <Toaster />
              </AuthProvider>
            </TooltipProvider>
          </ThemeProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </PosthogProvider>
    </StrictMode>,
  );
}
