import React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/features/ui/alert-dialog.tsx";
import { Button } from "@/features/ui/button.tsx";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { assistantMutations } from "@/services/mutations/assistant.ts";
import { useAssistantContext } from "@/features/dashboard/assistant/assistant.provider.tsx";
import { assistantQueries } from "@/services/queries/assistant.ts";
import { useNavigate } from "@tanstack/react-router";
import { useDashboardContext } from "@/features/dashboard/dashboard.provider.tsx";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/features/ui/drawer.tsx";

const DeleteAssistantButton: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const isMobile = useDashboardContext().isMobile;
  const [isOpen, setIsOpen] = React.useState(false);
  const assistant = useAssistantContext();
  const { mutate, status } = useMutation(assistantMutations.deleteAssistant());
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const handleDelete = () => {
    mutate(assistant.id, {
      onSuccess: async () => {
        setIsOpen(false);
        queryClient.setQueryData(
          assistantQueries.getAssistants().queryKey,
          (oldData) => {
            if (!oldData) return [];
            const index = oldData.findIndex(
              (assistant) => assistant.id === assistant.id,
            );
            oldData.splice(index, 1);
            return oldData;
          },
        );
        await navigate({ to: "/assistants" });
      },
    });
  };

  if (isMobile) {
    return (
      <Drawer open={isOpen} onOpenChange={setIsOpen}>
        <DrawerTrigger asChild>{children}</DrawerTrigger>
        <DrawerContent>
          <DrawerHeader>
            <DrawerTitle>Удалить ассистента</DrawerTitle>
            <DrawerDescription>
              Вы действительно хотите удалить ассистента?
            </DrawerDescription>
          </DrawerHeader>
          <DrawerFooter>
            <Button
              onClick={handleDelete}
              variant="destructive"
              className="w-full"
              status={status}
            >
              Удалить
            </Button>
            <DrawerClose>
              <Button variant="outline" className="w-full">
                Отменить
              </Button>
            </DrawerClose>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  }

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Вы действительно хотите удалить ассистента?
          </AlertDialogTitle>
          <AlertDialogDescription>
            Это действие не может быть отменено
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Отменить</AlertDialogCancel>
          <AlertDialogAction onClick={handleDelete} variant="destructive">
            <Button variant="destructive" status={status}>
              Удалить
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteAssistantButton;
