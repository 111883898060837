import React from "react";
import { createFileRoute, Outlet } from "@tanstack/react-router";

const AuthLayoutComponent = React.lazy(
  () => import("@/features/auth/layout.tsx"),
);

export const Route = createFileRoute("/_auth")({
  component: () => {
    return (
      <AuthLayoutComponent>
        <Outlet />
      </AuthLayoutComponent>
    );
  },
});
