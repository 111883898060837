import React from "react";
import DeleteAssistantButton from "@/features/dashboard/assistant/playground/gpt-chat/assistant-delete.button.tsx";
import ClearGptChatButton from "@/features/dashboard/assistant/playground/gpt-chat/gpt-chat-clear.button.tsx";
import { useDashboardContext } from "@/features/dashboard/dashboard.provider.tsx";
import SectionHeader from "@/features/layout/sectionHeader.tsx";
import { Playground } from "@/schemas/clients.ts";
import { SendIcon, Trash2Icon } from "lucide-react";
import { Button } from "@/features/ui/button.tsx";
import { cn } from "@/lib/utils.ts";
import { Link } from "@tanstack/react-router";
import { useAssistantContext } from "@/features/dashboard/assistant/assistant.provider.tsx";

type GptChatHeaderProps = {
  playground?: Playground;
};

const GptChatHeader: React.FC<GptChatHeaderProps> = ({ playground }) => {
  const assistant = useAssistantContext();
  const isMobile = useDashboardContext().isMobile;
  const tokens =
    playground && !Array.isArray(playground)
      ? playground.token_expenses_out + playground.token_expenses_in
      : 0;

  return (
    <SectionHeader
      leadingIcon="back"
      endingIcon={
        <ClearGptChatButton
          client_id={playground ? playground.id : undefined}
          variant="ghost"
          size="icon"
        />
      }
    >
      {isMobile ? (
        <div className="text-center flex flex-col">
          <span
            className={cn("font-bold text-xl", {
              "text-base": tokens > 0,
            })}
          >
            Чат с ботом
          </span>
          {tokens > 0 && (
            <span className="text-xs text-accent">
              {tokens.toLocaleString("ru-RU")} токенов
            </span>
          )}
        </div>
      ) : (
        <div className="w-full flex items-center justify-between">
          <div className="flex items-center gap-2">
            <DeleteAssistantButton>
              <Button variant="secondary">
                <Trash2Icon className="h-4 w-4" />
              </Button>
            </DeleteAssistantButton>
            <Button variant="secondary" className="gap-1" asChild>
              <Link
                to="/assistants/$id/chats"
                params={{ id: String(assistant.id) }}
              >
                <SendIcon className="h-4 w-4" />
                Экспорт
              </Link>
            </Button>
          </div>
          <div className="flex flex-row-reverse items-center gap-2">
            <ClearGptChatButton
              client_id={playground ? playground.id : undefined}
              variant="secondary"
            />
            {playground && !Array.isArray(playground) && (
              <span className="text-sm">
                {(
                  playground.token_expenses_in + playground.token_expenses_out
                ).toLocaleString("ru-RU")}{" "}
                токенов
              </span>
            )}
          </div>
        </div>
      )}
    </SectionHeader>
  );
};

export default GptChatHeader;
